import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Header from "../components/header"
import { Subheading } from "../components/styles/Headings"
import Seo from "../components/seo"
import { SectionContainerGridFourCols, SectionWrapperSmall } from "../components/styles/Sections"
import SlotComponent from "../components/SlotComponent"
import Footer from "../components/footer"
import BreadCrumbs from "../components/breadcrumbs"
import { SectionBlue } from "../components/styles/Sections"

const BlogIndex = ({
  data,
  location,
}) => {

  const slots = data.allWpSlot.nodes;
  const options = data.allWp.nodes[0].acfOptionsSlotsArkivInstallningar.slotsOptions;
  const archiveName = location.pathname.replaceAll('/', '');
  const year = new Date().getFullYear();

  /* CUSTOM ARRAY TO RENDER BREADCRUMBS AS IT IS AN ARCHIVE PAGE */
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  const crumbs = [ 
    {
    "text": "Hem",
    "url": "https://svenskaonlinecasinon.se"
    },
    {
      "text": capitalizeFirstLetter(archiveName),
      "url": "/" + archiveName
    }
  ]

  return (
    <Layout isHomePage>
      <Seo title={"Casino slots - Alla spelautomater på nätet " + year} description="Här hittar du all information om populära spelautomater på nätet. Vilka casino slots ger dig störst möjlighet att vinna?" />
      <BreadCrumbs items={crumbs} hideDate={true} />
      <Header 
        title={options.titel}
        description={options.introduktion}
      />
      <SectionBlue>
        <SectionWrapperSmall>
          <Subheading className="marginBottom">{options.subtitel} {year}</Subheading>
          <SectionContainerGridFourCols>
            {slots.map((item, i) => (
              <SlotComponent item={item} key={i} />
            ))}
          </SectionContainerGridFourCols>
        </SectionWrapperSmall>
        </SectionBlue>
        {!!options.content && (
          <section id="slot-content" className="containerNarrow">
            {parse(options.content)}
          </section>
        )}
      <Footer />
    </Layout>
  )
}

export default BlogIndex

export const slotQuery = graphql`
  query WordPressSlotArchive {
    allWp {
      nodes {
        acfOptionsSlotsArkivInstallningar {
          slotsOptions {
            content
            fieldGroupName
            introduktion
            subtitel
            titel
          }
        }
      }
    }
    allWpSlot(
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        uri
        date(locale: "sv-SE", formatString: "MMMM DD, YYYY")
        title
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
`
