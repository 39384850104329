import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image"

const SlotComponent = ({ item }) => {
  const featuredImage = {
    data: item.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: item.featuredImage?.node?.altText || ``,
  }

  return (
    <Slot>
      <Link to={item.uri}>
        <Image>
          {item.tags &&
            <span className="slotname">{item.tags.nodes[0].name}</span>
          }      
          {featuredImage?.data && (
            <GatsbyImage
              image={featuredImage.data}
              alt={featuredImage.alt}
              style={{ 
                borderRadius: 4
              }}
            />
          )}
        </Image>
      </Link>
      
      <div>
        <Link to={item.uri}>
          <div className="articleTitle">{item.title}</div>
        </Link>  
      </div>
      {}
    </Slot>
  );
}

export default SlotComponent;

const Slot = styled.div`
  width: 100%;
  height: min-content;
  row-gap: 10px;
  border-radius: 8px;

  a {
    text-decoration: none;
  }

  .articleTitle {
    margin: 0;
    font-size: 19px;
    color: var(--color-heading);
    font-weight: 700;
    font-family: var(--fontFamily-sans);
    line-height: 1.2;
    display: block;
    letter-spacing: -0.025em;
  }

  span {
    font-size: 14px;
    font-weight: var(--fontWeight-medium);
    color: var(--color-light);
  }

  p {
    font-size: 14px;
    line-height: 1.3;
    font-weight: var(--fontWeight-medium);
    color: var(--color-text);
    margin: 5px 0 0;

    a {
      display: none;
    }
  }
`;

const Image = styled.div`
  position: relative;

  .slotname {
    position: absolute;
    top: 12px;
    right: 12px;
    color: #fff;
    display: block;
    z-index: 1;
    background: rgb(245 245 245 / 27%);
    padding: 2px 5px;
    border-radius: 4px;
  }
`;